import React, { useMemo } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import PropTypes from 'prop-types';
import TalentInsightsHeader from "./Header";
import AdminGroupList from "./GroupList";
import AdminPathwayList from "./PathwayList";
import { useApiGet } from "../../hooks/useApiGet";
import { CLUSTERS_API_URL, GROUPS_API_URL, PATHWAYS_API_URL } from "../../constants";

const LoadingPlaceholder = () => <div className="loading-placeholder"><Spinner color="primary" /></div>;

const CareerCluster = ({ current_user }) => {
    const clusterId = useMemo(() => window.location.pathname.split('/')[3], []);

    const { data: cluster, loading: clusterLoading, error: clusterError } = useApiGet(`${CLUSTERS_API_URL}${clusterId}`);
    const { data: groups, loading: groupsLoading, error: groupsError } = useApiGet(`${GROUPS_API_URL}?category=${clusterId}`);
    const { data: pathways, loading: pathwaysLoading, error: pathwaysError } = useApiGet(`${PATHWAYS_API_URL}?category=${clusterId}`);

    const getLocalizedName = (item) => {
        if (!item) return '';
        switch (current_user?.current_language_code) {
            case 'fi': return item.name_fi;
            case 'de': return item.name_de;
            default: return item.name;
        }
    };

    return (
        <Container className="Main MainAdminFullWidth MainWithHero">
            <div className="Centralizer White">
                <TalentInsightsHeader current_user={current_user} />
                <Row>
                    <Col>
                        <div className="Box DarkPurple TalentInsightsBox">
                            <h4>Cluster: {clusterLoading ? <LoadingPlaceholder /> : cluster?.pk}</h4>
                            <h2>{clusterLoading ? <LoadingPlaceholder /> : getLocalizedName(cluster)}</h2>
                            {clusterError && <div className="error-message">Error loading cluster data</div>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>Related Career Pathways</h3>
                        {pathwaysLoading ? (
                            <LoadingPlaceholder />
                        ) : pathwaysError ? (
                            <div className="error-message">Error loading pathways</div>
                        ) : (
                            <AdminPathwayList pathways={pathways} current_user={current_user} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>Related Occupation Groups</h3>
                        {groupsLoading ? (
                            <LoadingPlaceholder />
                        ) : groupsError ? (
                            <div className="error-message">Error loading groups</div>
                        ) : (
                            <AdminGroupList groups={groups} current_user={current_user} />
                        )}
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

CareerCluster.propTypes = {
    current_user: PropTypes.shape({
        current_language_code: PropTypes.string
    })
};

export default CareerCluster;
