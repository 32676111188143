import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import axios from "axios";
import axiosInstance from './../../utils/Axios'; // Adjust the path as necessary


import { GROUPS_API_URL } from "../../constants";
import AdminGroupList from "./GroupList";
import TalentInsightsHeader from "./Header";


const OccupationGroups = (props) => {
    const token = localStorage.getItem('accessToken');
    let [groups, setGroups] = useState([]);
    let [group_search, setGroupSearch] = useState('');
    let [page, setPage] = useState(1);

    const handleGroupSearch = (event) => {
        setGroupSearch(event.target.value);
        getFilteredGroups(event.target.value);
    };
    const handlePagination = (page) => {
        setPage(page);
        getFilteredGroups(group_search);
    };

    const getGroups = () => {
        axios.get(GROUPS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(res => setGroups(res.data))
        .catch(err => {
            console.error('Error fetching groups', err);
        });
    
    };

    const getFilteredGroups = (recruitment_title) => {
        let complete_url = GROUPS_API_URL + '?name=' + recruitment_title + '&page=' + page
        axiosInstance.get(complete_url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setGroups(res.data))
        .catch(err => {
            console.error('Error fetching groups', err);
        });
    };

    useEffect(() => getGroups(), []);

    return (
        <Container className="Main MainAdminFullWidth MainWithHero">
        <div className="Centralizer White">
        <TalentInsightsHeader 
            current_user={props.current_user}
        />
        <Row>
            <Col>
                <div className="Box DarkPurple TalentInsightsBox">
                    <h2>
                        {props.current_user?.current_language_code == 'en' ? 'Job Groups' : ''}
                        {props.current_user?.current_language_code == 'fi' ? 'Ammattiryhmät' : ''}
                        {props.current_user?.current_language_code == 'de' ? 'Job Groups' : ''}
                    </h2>
                    <div className="RecruitmentSearch AdminFilter">
                        <input className="TextInput" placeholder="Search" value={group_search} onChange={handleGroupSearch}/>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
          <Col>
            <AdminGroupList
                groups={groups}
                group_search={group_search}
                handlePagination={handlePagination}
                current_user={props.current_user}
            />
          </Col>
        </Row>
    </div>
      </Container>
    );
}

export default OccupationGroups;
